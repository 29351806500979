/** @jsx jsx */
import * as React from 'react';
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import ReactJWPlayer from 'react-jw-player';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import tw, { styled } from 'twin.macro';
import { jsx, css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faWindowClose, faShoppingCart, faPlane } from '@fortawesome/free-solid-svg-icons';
import { Formik, useFormik } from 'formik';
import { PieChart } from 'react-minimal-pie-chart';
import PromoBlocks from '../components/standardPromoBlock';
import IndexHero from '../components/indexHero';
import IndexSwiper from '../components/indexSwiper';

import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import IndexTestimonialSwiper from '../components/indexTestimonialSwiper';
import IndexSolutions from '../components/indexSolutions';
import IndexProducts from '../components/indexProducts';
import IndexCaseStudies from '../components/indexCaseStudies';
import IndexBlogs from '../components/indexBlogs';
import IndexLeadership from '../components/indexLeadership';
import IndexWhitepaper from '../components/indexWhitepaper';
import NewEvalForm from '../components/newEvalForm';
import IndexLogos from '../components/indexLogos';
import IndexIndustryRecognition from '../components/indexIndustryRecognition';
import IndexInvestors from '../components/indexInvestors';
import IndexFreeTrial from '../components/indexFreeTrial';

// import { Cell, Legend, Label, LabelList, ResponsiveContainer, Pie, PieChart, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

Modal.setAppElement('body');

const IndexPage = () => (
  <Layout>
    <SEO title="Zycada - Drive the fastest and most engaging experiences in eCommerce, streaming and SaaS" />

    <IndexHero />

    <IndexSwiper />

    <IndexLogos />

    <div className={'flex flex-col flex-wrap align-middle justify-center py-8 mx-auto '}>
      <div className={'w-full text-center'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Recognized for Excellence</h1>
      </div>
      <IndexIndustryRecognition />
    </div>

    <div className={'flex flex-wrap justify-center py-8'}>
      <div className={'w-full text-center'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Customer Testimonials</h1>
      </div>
      <IndexTestimonialSwiper />
    </div>

    <div className={'flex flex-col flex-wrap justify-center py-8 '}>
      <div className={'text-center mx-auto'} id={'solutions'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Solutions</h1>
      </div>
      <IndexSolutions />
    </div>

    <div className={'flex flex-col flex-wrap justify-center py-8 '}>
      <div className={'text-center mx-auto'} id={'products'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Products</h1>
      </div>

      <IndexProducts />

      {/*</div>*/}
    </div>

    <div className={'flex flex-col justify-center py-8 '}>
      <div className={'text-center mx-auto'} id={'case-studies'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Case Studies</h1>
      </div>

      <IndexCaseStudies />

      {/*</div>*/}
    </div>

    {/* <div className={'flex flex-col justify-center py-8 '}>
      <div className={'text-center mx-auto'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Whitepaper</h1>
      </div>
      <IndexWhitepaper />
    </div> */}

    <div className={'flex flex-col justify-center py-8 '}>
      <div className={'text-center mx-auto'}>
        <h1 className={'text-zycadaDarkPurple px-4'}>Blogs</h1>
      </div>

      <IndexBlogs />

      {/*</div>*/}
    </div>

    {/* <div className={"flex flex-col justify-center py-8 "}>
      <div className={"text-center mx-auto"}  id={"company"}>
        <h1 className={"text-zycadaDarkPurple px-4"}>Leadership</h1>
      </div>

      <IndexLeadership />

    </div> */}

    {/* <div className={"flex flex-col justify-center py-8 "}>
      <div className={"text-center mx-auto"}>
        <h1 className={"text-zycadaDarkPurple px-4"}>Investors</h1>
      </div>

      <IndexInvestors />

    </div> */}

    <IndexFreeTrial />

    {/*<p>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    {/*</p>*/}
  </Layout>
);

export default IndexPage;
